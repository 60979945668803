<template>
  <div class="ApplyInvoice container">
    <!-- 申请开票标题信息 -->
    <div class="ApplyInvoice-head">
      <h3 class="ApplyInvoice-title">申请开票</h3>
      <div class="ApplyInvoice-tips">
        <span><InfoCircleOutlined class="db-mr8" />温馨提示：</span>
        <span>1）电子发票在申请提交后24小时内自动开具(如在月初1-6号则48小时内开具</span>
        <span>2）增值税专用发票(纸质)如资质审核通过，将在申请提交后3个工作日内为您寄出，邮寄信息详见收票人信息</span>
      </div>
    </div>
    <!-- 申请开票标题信息 -->

    <!-- 订单信息 -->
    <div class="ApplyInvoice-info">
      <h4 class="Apply-Subtitle">订单信息</h4>
      <!-- <span>单订单</span> -->
    </div>
    <!-- 单订单 -->
    <!-- <div class="ApplyInvoice-content">
      <div class="ApplyInvoice-content-item"><span>订单编号：</span><span>DDBH20211284973-01</span></div>
      <div class="ApplyInvoice-content-item"><span>支付时间：</span><span>2021-07-21 12:21:33</span></div>
      <div class="ApplyInvoice-content-item"><span>支付方式：</span><span>线上支付</span></div>
      <div class="ApplyInvoice-content-item"><span>开票金额：</span><span>¥2,200.00</span></div>
    </div> -->
    <!-- 单订单 -->
    <!-- 多订单 -->
    <div class="Invoice-table">
      <a-table
        :row-key="record => record.orderId"
        :data-source="dataSource"
        :columns="columns"
        :pagination="false"
      />
      <div class="footer">
        <span>合计金额</span>
        <span>￥{{ totalAmount }}</span>
      </div>
    </div>
    <!-- 多订单 -->
    <!-- 订单信息 -->

    <!-- 选择发票 -->
    <div class="ApplyInvoice-info">
      <h4 class="Apply-Subtitle">选择发票抬头</h4>
    </div>
    <div class="db-mb24">
      <a-radio-group v-model:value="InvoiceType" @change="InvoiceTypeFun">
        <a-radio :value="1" class="db-mr48">企业单位</a-radio>
        <a-radio :value="2">个人/非企业单位</a-radio>
      </a-radio-group>
    </div>
    <div class="ApplyInvoice-list">
      <a-radio-group v-model:value="billId">
        <a-row>
          <a-col v-for="item in InvoiceList" :key="item.id" class="db-mb16" :span="24">
            <a-radio :value="item.id">{{ item.billTitle }}</a-radio>
          </a-col>
          <span v-if="InvoiceList.length === 0">暂无</span>
        </a-row>
      </a-radio-group>
    </div>
    <div class="ApplyInvoice-info">
      <h4 class="Apply-Subtitle">选择发票类型</h4>
    </div>
    <div class="db-mb24">
      <a-radio-group v-model:value="InvoiceEnterType">
        <a-radio :value="1" class="db-mr48">电子普通发票</a-radio>
        <a-radio :value="2">增值税专用发票</a-radio>
      </a-radio-group>
    </div>

    <div class="ApplyInvoice-info">
      <h4 class="Apply-Subtitle">选择收获地址</h4>
    </div>
    <div class="db-mb24">
      <a-radio-group v-model:value="InvoiceAddress">
        <a-row>
          <a-col v-for="item in addressList" :key="item.id">
            <a-radio :value="item.id" class="db-mr48">
              {{ item.province }}
              {{ item.city }}
              {{ item.region }}
              {{ item.detailAddress }}
            </a-radio>
          </a-col>
          <a-col v-if="addressList.length === 0">暂无</a-col>
        </a-row>
      </a-radio-group>
    </div>
    <!-- 选择发票 -->
    <div class="ApplyInvoice-btn">
      <a-button @click="confirmFun">提交</a-button>
      <a-button @click="goBack">返回</a-button>
    </div>
  </div>
</template>

<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { filterVal } from '@/utils'
import {
  getMemberBillByType,
  invoicing
} from '@/api/invoice'
import { listAll } from '@/api/user'
import { mapState } from 'vuex'
export default {
  name: 'ApplyInvoice',
  components: {
    InfoCircleOutlined
  },
  data() {
    return {
      payTypeList: [
        {
          value: 0,
          label: '线上支付'
        },
        {
          value: 1,
          label: '线下支付'
        }
      ],
      InvoiceType: 1,
      InvoiceEnterType: 1,
      InvoiceAddress: '',
      billId: '',
      dataSource: [],
      columns: [
        {
          title: '订单编号',
          dataIndex: 'orderSn',
          key: 'orderSn'
        },
        {
          title: '发票状态',
          dataIndex: 'status',
          key: 'status',
          customRender: (text) => {
            return filterVal(text.text, this.statusList, 'value', 'text')
          }
        },
        {
          title: '支付时间',
          dataIndex: 'paymentTime',
          key: 'paymentTime'
        },
        {
          title: '支付方式',
          dataIndex: 'payType',
          key: 'payType',
          customRender: (text) => {
            return filterVal(text.text, this.payTypeList)
          }
        },
        {
          title: '可开票金额',
          dataIndex: 'payAmount',
          key: 'payAmount',
          sorter: (a, b) => a.KP_price - b.KP_price
        },
        {
          title: '操作',
          dataIndex: 'address',
          key: 'address',
          slots: {
            customRender: 'action'
          }
        }
      ],
      statusList: [
        { text: -1, value: '未申请' },
        { text: 0, value: '审核中' },
        { text: 1, value: '审核通过' },
        { text: 2, value: '发票已开' }
      ],
      InvoiceList: []
    }
  },
  computed: {
    ...mapState({
      addressList: state => state.user.address,
      User_ID: state => state.user.id
    }),
    totalAmount() {
      const totalAmount = this.dataSource.reduce((cur, item) => {
        cur += item.payAmount
        return cur
      }, 0)
      return totalAmount
    }
  },
  created() {
    if (this.$route.query.data) {
      this.dataSource = JSON.parse(this.$route.query.data)
    }
  },
  mounted() {
    // this.getAddressList()
    this.getBillByTypeFun(1)
  },
  methods: {
    getAddressList() {
      listAll(this.User_ID).then(res => {
        this.addressList = res.data
        this.$store.dispatch('user/changeAddress', res.data)
      })
    },
    InvoiceTypeFun(e) {
      if (e.target.value) {
        this.getBillByTypeFun(e.target.value)
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    confirmFun() {
      if (!this.billId) {
        this.$message.warning('请选择发票抬头')
        return
      }
      if (!this.InvoiceEnterType) {
        this.$message.warning('请选择发票类型')
        return
      }
      if (!this.InvoiceAddress) {
        this.$message.warning('请选择发票地址')
        return
      }
      const data = {
        orderIds: [],
        billId: this.billId,
        enterType: this.InvoiceEnterType,
        memberAddressId: this.InvoiceAddress
      }
      this.dataSource.forEach(item => {
        data.orderIds.push(item.orderId)
      })
      invoicing(data).then(res => {
        if (res.code === 0) {
          this.$message.success('开票成功')
          this.$router.go(-1)
        }
      })
    },
    getBillByTypeFun(type) {
      const data = {
        billType: type
      }
      getMemberBillByType(data).then(res => {
        this.InvoiceList = res.data
      })
    },
    filterVal
  }
}
</script>
<style lang='less' scoped>
.ApplyInvoice {
  padding: 64px 0 164px;

  // 申请开票标题信息
  .ApplyInvoice-head {
    margin-bottom: 64px;
    .ApplyInvoice-title {
      font-size: 36px;
      color: #333333;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .ApplyInvoice-tips {
      width: 100%;
      height: 113px;
      background: #F9F1DD;
      padding: 16px 24px;
      display: flex;
      flex-direction: column;

      & > span {
        font-weight: 400;
        color: #666666;
      }
      & > span:first-child {
        display: block;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  // 申请开票标题信息

  // 订单信息
  .ApplyInvoice-info {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .Apply-Subtitle {
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      margin-right: 24px;
    }
    & > span {
      display: inline-block;
      width: 88px;
      height: 24px;
      background: #3B6652;
      border-radius: 12px;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      line-height: 24px;
    }
  }
  .ApplyInvoice-content {
    width: 100%;
    height: 132px;
    background-color: #F8F8F8;
    padding: 16px 24px;
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ApplyInvoice-content-item {
      & > span {
        color: #666666;
        font-weight: 400;
      }
      & > span:first-child {
        margin-right: 26px;
      }
    }
  }
  .ApplyInvoice-list {
    width: 100%;
    padding: 16px 24px;
    background-color: #F8F8F8;
    margin-bottom: 64px;
  }

  .Invoice-table {
      border: 1px solid #E5E5E5;
      margin-bottom: 64px;

      .action-btn {
        & > button {
          height: 20px;
          padding: 1px 12px;
          background-color: #3B6652;
          color: #FFFFFF;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          line-height: 12px;
          margin-right: 16px;
          border-radius: 12px;
        }
      }

      .footer {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > span {
          font-size: 18px;
          color: #333333;
          margin-right: 142px;
          font-weight: bold;
        }
        & > button {
          width: 128px;
          height: 32px;
          background: #3B6652;
          border-radius: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 12px;
          margin-right: 16px;
        }
      }
    }
  // 订单信息

  .ApplyInvoice-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;

    & > button {
      width: 173px;
      height: 46px;
      border-radius: 32px;
      text-align: center;
      font-size: 16px;
      line-height: 16px;
    }
    & > button:first-child {
      background: #3B6652;
      margin-right: 160px;
      color: #fff;
    }
    & > button:last-child {
      border: 1px solid #E5E5E5;
      color: #999999;
    }
  }
}
:deep(.ant-table-thead > tr > th) {
  background-color: #D7E0DC;
}
</style>
