import request from '@/utils/request'

/**
 * 发票管理列表
*/
export function getMemberBillList() {
  return request({
    url: '/api/member/web/getMemberBillList',
    method: 'get'
  })
}
/**
 * 删除发票
 * [id]
*/
export function deleteInvoice(data) {
  return request({
    url: '/api/member/memberbill/delete',
    method: 'post',
    data
  })
}
/**
 * 修改发票
 * "id": 3,
	"member_id": 1,
	"billType": 1,
	"billTitle": "东邦御厨123",
	"billTax": "123IUHI123",
	"billAddress": "北京市建国门附近",
	"billPhone": "1788888888",
	"billBank": "61234353292893",
	"billBankNumber": "102391283901230809",
	"defaultStatus": 1
*/
export function updateInvoice(data) {
  return request({
    url: '/api/member/memberbill/update',
    method: 'post',
    data
  })
}
/**
 * 添加发票
 * "id": 3,
	"memberId": 1,
	"billType": 1, 1企业 2个人
	"billTitle": "东邦御厨123",
	"billTax": "123IUHI123",
	"billAddress": "北京市建国门附近",
	"billPhone": "1788888888",
	"billBank": "61234353292893",
	"billBankNumber": "102391283901230809",
	"defaultStatus": 1 1默认2非默认
*/
export function saveInvoice(data) {
  return request({
    url: '/api/member/memberbill/save',
    method: 'post',
    data
  })
}
/**
 * 订单的开发票的记录
 * payType=0&beginTime=2021-09-21 06:09:23&endTime=2021-09-24 06:09:23
 *
*/
export function getOrderBillAll(params) {
  return request({
    url: '/api/order/web/getOrderBillAll',
    method: 'get',
    params
  })
}
/**
 * 已开发票列表
 * paytype 0线上 1线下
*/
export function getOrderBill(params) {
  return request({
    url: '/api/order/web/getOrderBill',
    method: 'get',
    params
  })
}
/**
 * 获取 企业单位/非企业单位 发票抬头
 * billType=1
*/
export function getMemberBillByType(params) {
  return request({
    url: '/api/member/web/getMemberBillByType',
    method: 'get',
    params
  })
}
/**
 * 申请开票
 * orderIds=13&billId=1&enterType=1&memeberAddressId=1
 * enterType 1 普通发票 2 增值税发票
*/
export function invoicing(params) {
  return request({
    url: '/api/order/web/invoicing',
    method: 'post',
    params
  })
}
